@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;600;700&display=swap');
@import url('https://api.fontshare.com/v2/css?f[]=clash-display@400,500,600,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
@import './modal.css'; 

/* Font settings */
@layer base {
  html {
    font-family: 'Outfit', sans-serif;
    letter-spacing: -0.02em;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Clash Display', sans-serif;
    letter-spacing: -0.03em;
  }
}

/* Custom text styles */
.nav-link {
  font-family: 'Outfit', sans-serif;
  font-weight: 500;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-size: 0.9em;
}

.hero-text {
  font-family: 'Clash Display', sans-serif;
  font-weight: 600;
  letter-spacing: -0.02em;
  line-height: 1.3;
}

.section-title {
  font-family: 'Clash Display', sans-serif;
  font-weight: 600;
  letter-spacing: -0.03em;
  line-height: 1.1;
}

.body-text {
  font-family: 'Outfit', sans-serif;
  font-weight: 400;
  letter-spacing: -0.01em;
  line-height: 1.6;
}

/* Custom button styles */
.primary-button {
  font-family: 'Outfit', sans-serif;
  font-weight: 600;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

/* Custom animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes border-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Animation classes */
.animate-fadeIn {
  animation: fadeIn 1s ease-out;
}

.animate-slideUp {
  animation: slideUp 1s ease-out;
}

.animate-border-rotate {
  animation: border-rotate 20s linear infinite;
  opacity: 0.5;
}

/* Smooth scrolling */
html {
  scroll-behavior: smooth;
}

/* Custom background noise effect */
.bg-noise {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 400 400' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.9' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
  background-repeat: repeat;
  opacity: 0.4;
  mix-blend-mode: overlay;
}

/* Enhanced scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
}

/* Loading state for images */
img {
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
}

/* Enhanced focus states */
:focus {
  outline: 2px solid rgba(255, 255, 255, 0.5);
  outline-offset: 2px;
}

/* Remove focus outline for mouse users */
:focus:not(:focus-visible) {
  outline: none;
}

/* Improved button focus states */
button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
} 