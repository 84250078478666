/* modal.css */
.react-modal-overlay {
    background-color: rgba(0, 0, 0, 0.75);
  }
  
  .react-modal-content {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    marginRight: '-50%';
    transform: translate(-50%, -50%);
    backgroundColor: #000;
    border: none;
    padding: 0;
    width: 100%; /* Fill the entire screen width */
    height: 100%; /* Fill the entire screen height */
  }

/* Modal styles */
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  background-color: rgba(0, 0, 0, 0.85) !important;
  backdrop-filter: blur(8px);
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  transform: scale(0.95);
  transition: transform 300ms ease-in-out;
}

.ReactModal__Content--after-open {
  transform: scale(1);
}

.ReactModal__Content--before-close {
  transform: scale(0.95);
}

/* Modal close button animation */
.modal-close-btn {
  transition: all 300ms ease-in-out;
}

.modal-close-btn:hover {
  transform: rotate(90deg);
  background-color: rgba(255, 255, 255, 0.2);
}

/* Video player container */
.video-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Loading animation for video */
.video-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 48px;
  height: 48px;
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-left-color: white;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}